<template>
  <div class="pics_block">
    <!-- DateTimeBar -->
    <div>
      <v-row no-gutters class="timerBckGrnd" justify="center" align="center">
        <v-col cols="2" align="center"  class="left_btn" @click="fetchPrevPicsName()">
          <a ><v-icon left>mdi-calendar-clock</v-icon></a> 
        </v-col>

        <v-col cols="8" align="center" class="timer_col">
          <div class="timer">
            <div id="casPorizeniSnimku">
                <v-progress-circular
                  v-show="isLoading"
                  indeterminate
                  color="red"
                  :size="20"
                ></v-progress-circular>
              {{ actPics[datumCasLang[currLang]] }}
            </div>
          </div>
          <div class="time_popis">{{ before[beforeValue].btnName[currLang]}}</div>
        </v-col>

        <v-col cols="2" align="center" class="right_btn" @click="fetchPicsName()">
          <a ><v-icon left>mdi-calendar-today</v-icon></a>
        </v-col>
      </v-row>
    </div>

    <!-- IMG 1 -->
    <div v-if="onMobile">
      <v-card class="mx-auto" width="100%" flat>
        <v-list class="pa-0">
          <v-list-group :value="false" class="pa-0 listGroup">
            <template v-slot:activator>
              <v-list-item-title height="25" class="pa-0">{{
                kamera[currLang] + " #1"
              }}</v-list-item-title>
            </template>
            <v-img
              v-bind:src="this.$myServerPath + `/campics/${actPics[3]}`"
              alt="Camera1"
              @click="setBigImg(3)"
            >
            </v-img>
          </v-list-group>
        </v-list>
      </v-card>
      <!-- IMG 2 -->
      <v-card class="mx-auto" width="100%" flat>
        <v-list class="pa-0">
          <v-list-group :value="true" class="pa-0 listGroup">
            <template v-slot:activator>
              <v-list-item-title height="25" class="pa-0">{{
                kamera[currLang] + " #2"
              }}</v-list-item-title>
            </template>
            <v-img
              v-bind:src="this.$myServerPath + `/campics/${actPics[2]}`"
              alt="Camera2"
              @click="setBigImg(2)"
            >
            </v-img>
          </v-list-group>
        </v-list>
      </v-card>
      <!-- IMG 3 -->
      <v-card class="mx-auto" width="100%" flat>
        <v-list class="pa-0">
          <v-list-group :value="false" class="pa-0 listGroup">
            <template v-slot:activator>
              <v-list-item-title height="25" class="pa-0">{{
                kamera[currLang] + " #3"
              }}</v-list-item-title>
            </template>
            <v-img
              v-bind:src="this.$myServerPath + `/campics/${actPics[1]}`"
              alt="Camera3"
              @click="setBigImg(1)"
            >
            </v-img>
          </v-list-group>
        </v-list>
      </v-card>
      <!-- IMG 4 -->
      <v-card class="mx-auto" width="100%" flat>
        <v-list class="pa-0">
          <v-list-group :value="false" class="pa-0 listGroup">
            <template v-slot:activator>
              <v-list-item-title height="25" class="pa-0">{{
                kamera[currLang] + " #4"
              }}</v-list-item-title>
            </template>
            <v-img
              v-bind:src="this.$myServerPath + `/campics/${actPics[0]}`"
              alt="Camera4"
              @click="setBigImg(0)"
            >
            </v-img>
          </v-list-group>
        </v-list>
      </v-card>
    </div>
    
    <!-- All Images -->
    <div v-if="!onMobile" class="snimky">
      <div class="snimek_blok">
        <img
          class="snimek"
          v-bind:src="this.$myServerPath + `/campics/${actPics[3]}`"
          @click="setBigImg(3)"
        />
        <img class="snimekPopis" src="@/assets/Pics/1.png" />
      </div>
      <div class="snimek_blok">
        <img
          class="snimek"
          v-bind:src="this.$myServerPath + `/campics/${actPics[2]}`"
          @click="setBigImg(2)"
        />
        <img class="snimekPopis" src="@/assets/Pics/2.png" />
      </div>
      <div class="snimek_blok">
        <img
          class="snimek"
          v-bind:src="this.$myServerPath + `/campics/${actPics[1]}`"
          @click="setBigImg(1)"
        />
        <img class="snimekPopis" src="@/assets/Pics/3.png" />
      </div>
      <div class="snimek_blok">
        <img
          class="snimek"
          v-bind:src="this.$myServerPath + `/campics/${actPics[0]}`"
          @click="setBigImg(0)"
        />
        <img class="snimekPopis" src="@/assets/Pics/4.png" />
      </div>
    </div>

    <!-- Big img -->

    <v-dialog v-model="dialog" fullscreen>
      <img
        class="bigImage"
        :src="this.$myServerPath + `/campics/${actPics[modalImgIndex]}`"
        @click="setBigImg(undefined)"
      />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "PicsBlock",
  props: { currLang: String, onMobile: Boolean },
  created: function () {
    this.fetchPicsName();
    //this.fetchDatumCas();
  },
  computed: {
    
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      //datumCas: [],

      datumCasLang: {
        en: "5",
        cz: "4",
      },

      actPics: [],
      kamera: {
        en: "CAMERA",
        cz: "KAMERA",
      },
      dialog: false,
      modalImgIndex: "",

      beforeValue: 0,
      before: [
        {time: "now", btnName: {cz: "poslední snímky", en: "last pictures"}},
        {time: "hour", btnName: {cz: "před hodinou", en: "before 1 hour"}},
        {time: "6hour", btnName: {cz: "před šesti hodinami", en: "before 6 hours"}},
        {time: "12hour", btnName: {cz: "před dvanácti hodinami", en: "before 12 hours"}},
        {time: "day", btnName: {cz: "včera", en: "Yesterday"}},
        {time: "week", btnName: {cz: "před týdnem", en: "before 1 week"}},
        {time: "month", btnName: {cz: "před měsícem", en: "before 1 month"}}
      ],
      isLoading: false
    };
  },

  methods: {
    fetchPicsName() {
      this.isLoading = true;
      axios.get(this.$myServerPath + "/last4PicsName.php").then((response) => {
        this.actPics = response.data;
        this.beforeValue = 0;
        setTimeout(()=>this.isLoading = false,1000);
      });
    },

    fetchPrevPicsName() {
      this.isLoading = true;
      if(this.beforeValue != 6) {
        this.beforeValue = this.beforeValue + 1;
      } else this.beforeValue = 0;
      axios.post(this.$myServerPath + "/beforePicsName.php", {
      beforeValue: this.beforeValue
      })
      .then((response) => {
        this.actPics = response.data;
        setTimeout(()=>this.isLoading = false,1000);
      });
    },

    setBigImg(index) {
      if (!this.onMobile) {
        this.modalImgIndex = index;
        this.dialog = !this.dialog;
      }
    },
    // fetchDatumCas() {
    //   axios.post(this.$myServerPath + "/dateLast4Pics.php").then((response) => {
    //     this.datumCas = response.data;
    //   });
    // },
  },
};
</script>

<style scopped>
.pics_block {
  margin-top: 80px;
}
.timerBckGrnd {
  height: 60px;
  background-color: #0154ce;
}
.timer {
  display: flex;
  flex: 1 1 35%;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 30px;
  border-radius: 0 0 20px 20px;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  font-family: "gibsonlight";
}
#casPorizeniSnimku {
  font-size: 16px;
  text-align: center;
  color: white;
}
.time_popis{
  display: flex;
  align-items: center;
  justify-content: center;
  color: lightgray;
  font-family: "gibsonlight";
  height: 30px;
}
.left_btn,
.right_btn {
  color: white !important;
  cursor: pointer;
}

#app > div > div.mx-auto.v-card.v-card--flat.v-sheet.theme--light.blue > div {
  padding: 0;
}
#app
  > div
  > div.mx-auto.v-card.v-card--flat.v-sheet.theme--light.blue
  > div
  > div
  > div.v-list-group__header {
  min-height: 25px;
  background-color: #0154ce;
}
.listGroup {
  background-color: #0154ce;
}
#app > div > div > div > div > div {
  padding: 0;
}
.v-list-item__title {
  color: white;
}
.mdi:before {
  color: white;
}
.snimky {
  display: flex;
}
.snimek_blok {
  position: relative;
  flex: 1 1 25%;
}
.snimek {
  max-width: 100%;
}
.snimekPopis {
  position: absolute;
  top: 5%;
  right: 5%;
}
.bigImage {
  object-fit: contain;
  max-height: calc(100vh - 100px);
  max-width: 100vw;
  cursor: pointer;
}
.v-progress-circular {
  margin: 1rem;
}

@media (min-width: 600px) and (max-width: 900px) {
  .snimky {
    flex-wrap: wrap;
  }
  .snimek_blok {
    flex: 1 1 50%;
    width: 50%;
  }
  .bigImage {
    max-height: 100vh;
  }
}
@media (max-width: 600px) {
  .bigImage {
    max-height: 100vh;
  }
  .timerBckGrnd {
    border-bottom: 1px solid white;
  }
  .pics_block {
    margin-top: 60px;
  }
  .listGroup {
    border-bottom: 1px solid white;
  }
}
</style>