<template>
    <div class="horniLista" align="center" >
      
      <div class="logo">
        <img class="logo_img" src="@/assets/Pics/mainLogo.png" alt="Logo">
      </div>

      <div class="langSelector" >
        <table>
          <tr>
            <td v-on:click="switchCZ" :class="czBarva" id="cz">CZ</td>
            <td v-on:click="switchEN" :class="enBarva" id="en">EN</td>
          </tr>
          <tr>
            <td v-on:click="switchDegC" :class="DegC" id="stC">°C</td>
            <td v-on:click="switchDegF" :class="DegF" id="stF">°F</td>
          </tr>
        </table>
      </div>

    </div>
</template>

<script>
export default {
  name: "TopBar",
  data() {
    return {
      czBarva: "red--text",
      enBarva: "white--text",
      DegC: "red--text",
      DegF: "white--text",
    };
  },
  methods: {
    switchEN() {
      this.$emit("switchEN");
      this.enBarva = "red--text";
      this.czBarva = "white--text";
    },
    switchCZ() {
      this.$emit("switchCZ");
      this.czBarva = "red--text";
      this.enBarva = "white--text";
    },
    switchDegC() {
      this.$emit("switchC");
      this.DegC = "red--text";
      this.DegF = "white--text";
    },
    switchDegF() {
      this.$emit("switchF");
      this.DegF = "red--text";
      this.DegC = "white--text";
    },
  },
};
</script>

<style scoped>
.horniLista {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: #0154ce;
  border-bottom: 1px solid white;
  height: 80px;
  width: 100%;
}
.logo {
  flex: 1 1 80%;
  display: flex;
  align-items: center;
  margin-left: 4vw;
}
.logo_img {
  flex: 1 1 60%;
  max-width: 50%;
}

.langSelector{
  flex: 1 1 20%;
}
table {
  font-family: "gibsonregular";
  border-collapse: collapse;
}
td {
  padding: 3px 20px 3px 20px;
  font-size: 16px;
}
td:first-child {
  border-right: 1px solid white;
}
.langEN,
.langCZ,
#en,
#cz {
  cursor: pointer;
}
@media (max-width: 800px){
  .logo_img {
  max-width: 100%;
  }
}
@media (max-width: 1000px){
  .logo_img {
  max-width: 80%;
  }
}
@media (max-width: 600px){
  .horniLista {
    height: 60px;
  }
  td {
    font-size: 12px;
}
}
</style>
