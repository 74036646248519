<template>
<v-app>
  <div id="app">
    <Home />
  </div>
</v-app>
</template>

<script>
import Home from "@/views/Home.vue";
export default {
  name: "App",
  components: {
    Home,
  },
  data() {
    return {};
  },
};
</script>

<style>
@font-face {
  font-family: "gibsonlight";
  src: url("Fonts/gibson-light-webfont.woff2") format("woff2"),
    url("Fonts/gibson-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gibsonsemibold";
  src: url("Fonts/gibson-semibold-webfont.woff2") format("woff2"),
    url("Fonts/gibson-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gibsonregular";
  src: url("Fonts/gibson-regular-webfont.woff2") format("woff2"),
    url("Fonts/gibson-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gibsonsemibold";
  src: url("Fonts/gibson-semibold-webfont.woff2") format("woff2"),
    url("Fonts/gibson-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

#app {
  font-family: "gibsonsemibold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
html {
  box-sizing: border-box;
  font-size: 100%; /* = 16px */
  min-width: 360px;
}
body {
  margin: 0;
  padding: 0;
}
p,
div {
  margin: 0;
  padding: 0;
}
.blue {
  color: #0154ce;
}
</style>
