<template>
  <div>
    <v-row no-gutters class="footer">
      <a class="odkaz" @click="showDialog"><v-icon left>mdi-heart-outline</v-icon>{{ provozovatel[currLang] }}</a>
      <a class="odkaz" :href="`${href1}`" target="_blank"
        ><v-icon left>mdi-earth</v-icon>Vila Tugendhat</a
      >
      <a class="odkaz" :href="`${href2}`" target="_blank"
        ><v-icon left>mdi-earth</v-icon> GoToBrno</a
      >
    </v-row>

    <v-dialog v-model="dialog" width="350" height="300">
      <v-card elevation="24">
        <v-card-title
          class="font-weight-thin text-Subtitle-1 white--text jjgreen"
        >
          Ing. Jana Vybíralová
        </v-card-title>
        <v-card-text class="pa-4">
          Antonína Slavíka 1315/10 <br />
          602 00 Brno <br />
          <a href="mailto:spravce@webcamerabrno.cz" class="email"
            >spravce@webcamerabrno.cz</a
          >
          <br>
        <a href="https://janjachym.cz" target="blank" class="web"
          >janjachym.cz</a>
        <!-- <a href="https://janjachym.cz" target="blank"
          ><img
            src="@/assets/JJBanner.png"
            alt="jj banner"
            style="width: 100%; margin-top: 15px; padding: 10px 20px; border: 1px solid lightgray; border-radius: 15px;"
        /></a> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "BottomBar",
  props: { currLang: String },
  computed: {
    href1() {
      if (this.currLang == "cz") {
        return "https://www.tugendhat.eu/cz/homepage.html";
      }
      return "https://www.tugendhat.eu/en/homepage.html";
    },
    href2() {
      if (this.currLang == "cz") {
        return "https://www.gotobrno.cz/";
      }
      return "https://www.gotobrno.cz/en/";
    },
  },
  data() {
    return {
      dialog: false,
      provozovatel: {
        cz: "Provozovatel",
        en: "Entrepreneur",
      },
      goToBrno: {
        cz: "https://gotobrno.cz/",
        en: "https://gotobrno.cz/",
      },
    };
  },
  methods: {
    showDialog() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style>
.footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 65px;
  width: 100%;
  background-color: #0154ce;
  color: white !important;
  font-family: "gibsonlight";  
}
.odkaz {
  color: white;
  padding: 3px;
}
a {
  text-decoration: none;
  color: white !important;
}
.email{
  text-decoration: none;
  color: #0154ce !important;
}
.web{
  color: #0154ce !important;
  
}
.v-dialog{
  border-radius: 20px;
  /* box-shadow: rgba(124, 200, 251, 0.5) -150px -150px 100px 80px, rgba(124, 200, 251, 0.5) 150px -150px 100px 80px, rgba(124, 200, 251, 0.5) 150px 150px 100px 80px, rgba(124, 200, 251, 0.5) -150px 150px 100px 80px; */
}
.v-card{
  border-radius: 20px;

}
.jjgreen{
  background-color: #0154ce;
 
}
@media (max-width: 600px){
  
}

</style>