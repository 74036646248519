<template>
  <div class="meteoBlok">
    <div class="hlavickaMeteo">
      <div>
        MeteoData -
        <a
          href="https://www.wunderground.com/weather/cz/brno/IBRNO550"
          target="_blank"
          >Weather underground<v-icon right>mdi-earth</v-icon></a
        >
        <!-- <a href="http://webcamerabrno.cz/weewx/index.html" target="_blank">WeeWX<v-icon right>mdi-earth</v-icon></a> -->
      </div>
    </div>

    <div class="meteoBody">
      <div class="meteoHodnoty">
        <table>
          <tr>
            <td>{{ teplota[currLang] }}</td>
            <td>{{ teplVal }}</td>
          </tr>
          <tr>
            <td>{{ pocitteplota[currLang] }}</td>
            <td>{{ pocTeplVal }}</td>
          </tr>
          <tr>
            <td>{{ vlhkost[currLang] }}</td>
            <td>{{ vlhkostVal }} %</td>
          </tr>
          <tr>
            <td>{{ tlak[currLang] }}</td>
            <td>{{ tlakVal }} hPa</td>
          </tr>
          <tr>
            <td>{{ srazky[currLang] }}</td>
            <td>{{ srazkyVal }} mm</td>
          </tr>
        </table>
      </div>
      <div class="meteoCompas">
        <div class="img-container">
          <img
            class="compasImg"
            v-bind:style="compasRotate"
            src="../assets/Pics/compasRedSmall.jpg"
            alt="compas"
          />
        </div>
        <table>
          <tr>
            <td>{{ wind[currLang] }}</td>
            <td>{{ vitrVal }} m/s</td>
          </tr>
        </table>
      </div>

      <div class="meteoSunMoon">
        <div class="meteoSunMoonBlock">
          <img class="imgSun" src="../assets/Pics/sunrise.png" />
          <div>{{ sunriseTime }}</div>
        </div>
        <div class="meteoSunMoonBlock">
          <img class="imgMoon" v-bind:src="`Pics/moon${moonPhase}.jpg`" />
          <div>{{ moonPhaseName[currLang][actualMoonPhase] }}</div>
        </div>
        <div class="meteoSunMoonBlock">
          <img class="imgSun" src="../assets/Pics/sunset.png" />
          <div>{{ sunsetTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "MeteoBlock",
  props: { currLang: String, unit: String },
  created() {
    // this.fromXLM();
    this.getData();
    this.getSunsetSunrise();
    this.getMoonPhase();
    this.getSunsetSunrise();
    this.getSunsetSunset();
  },
  computed: {
    compasRotate() {
      return { transform: "rotate(" + this.compasTurn + "deg)" };
    },
    teplVal() {
      if (this.unit === "F") {
        return `${Math.round(this.teplotaVal * 1.8 + 32)} °F`;
      }
      return `${this.teplotaVal} °C`;
    },
    pocTeplVal() {
      if (this.unit === "F") {
        return `${Math.round(this.pocitTeplotaVal * 1.8 + 32)} °F`;
      }
      return `${this.pocitTeplotaVal} °C`;
    },
  },
  data() {
    return {
      teplota: {
        en: "Temperature:",
        cz: "Teplota:",
      },

      pocitteplota: {
        en: "Wind Chill:",
        cz: "Pocitová teplota:",
      },

      vlhkost: {
        en: "Humidity:",
        cz: "Vlhkost:",
      },

      tlak: {
        en: "Pressure:",
        cz: "Tlak:",
      },

      srazky: {
        en: "Rain:",
        cz: "Srážky:",
      },
      srazkyZaDen: {
        en: "/day",
        cz: "/den",
      },
      wind: {
        en: "Wind:",
        cz: "Vítr:",
      },

      moonPhaseName: {
        en: [
          "New Moon",
          "Waxing Crescent",
          "Waxing Quarter",
          "Waxing Gibbous",
          "Full Moon",
          "Wanning Gibbous",
          "Wanning Quarter",
          "Wanning Crescent",
        ],
        cz: [
          "Novoluní",
          "Dorůstající srpek",
          "První čtvrt",
          "Dorůstající měsíc",
          "Úplněk",
          "Couvající měsíc",
          "Poslední čtvrt",
          "Ubývající srpek",
        ],
      },

      teplotaVal: "",
      pocitTeplotaVal: "0",
      vlhkostVal: "0",
      tlakVal: "0",
      srazkyVal: "0",
      vitrVal: "0.0",

      compasTurn: "0",

      actualMoonPhase: "",

      moonPhase: [
        "0",
        "30",
        "60",
        "90",
        "120",
        "180",
        "240",
        "270",
        "300",
        "330",
      ],
      moonFullness: "0",

      sunsetTime: "",
      sunriseTime: "",

      imgIndex: "",
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  methods: {
    getData() {
      axios.get(this.$myServerPath + "/getObservation.php").then((response) => {
        this.teplotaVal = response.data[0]["temp"];
        this.pocitTeplotaVal = response.data[0]["windChill"];
        this.vlhkostVal = response.data[0]["humidity"];
        this.tlakVal = response.data[0]["pressure"];
        this.srazkyVal = response.data[0]["precipTotal"];
        this.vitrVal = response.data[0]["windSpeed"];
        this.compasTurn = response.data[0]["winddir"] - 180;
      });
    },

    getSunsetSunrise(){
      axios.get(this.$myServerPath + "/getSunrise.php").then((response) => {
        this.sunriseTime = response.data;
      });
    },

    getSunsetSunset(){
      axios.get(this.$myServerPath + "/getSunset.php").then((response) => {
        this.sunsetTime = response.data;
      });
    },

 
    getMoonPhase() {
      axios.get(this.$myServerPath + "/MoonPhaseCalc.php").then((response) => {
        let moonPhase = response.data;
        switch (moonPhase) {
          case "New Moon":
            this.moonPhase = "0";
            this.actualMoonPhase = 0;
            break;
          case "Waxing Crescent Moon":
            this.moonPhase = "60";
            this.actualMoonPhase = 1;
            break;
          case "Quarter Moon":
            this.moonPhase = "90";
            this.actualMoonPhase = 2;
            break;
          case "Waxing Gibbous Moon":
            this.moonPhase = "120";
            this.actualMoonPhase = 3;
            break;
          case "Full Moon":
            this.moonPhase = "180";
            this.actualMoonPhase = 4;
            break;
          case "Waning Gibbous Moon":
            this.moonPhase = "240";
            this.actualMoonPhase = 5;
            break;
          case "Last Quarter Moon":
            this.moonPhase = "270";
            this.actualMoonPhase = 6;
            break;
          case "Waning Crescent Moon":
            this.moonPhase = "300";
            this.actualMoonPhase = 7;
            break;
        }
      });
    },

    fromXLM() {
      axios.get("/weewx/rss.xml").then((response) => {
        var xmlText = response.data;
        var parser = new DOMParser(),
          xmlDOC = parser.parseFromString(xmlText, "text/xml");
        var teplotaValTest = xmlDOC
          .getElementsByTagName("outsideTemperature")[0]
          .childNodes[0].nodeValue.slice(0, -2);
        if (teplotaValTest.includes("N")) {
          this.teplotaVal = "-";
        } else {
          this.teplotaVal = teplotaValTest;
        }
        this.pocitTeplotaVal = xmlDOC
          .getElementsByTagName("windchill")[0]
          .childNodes[0].nodeValue.slice(0, -2);

        var vlhkostValTest =
          xmlDOC.getElementsByTagName("humidity")[0].childNodes[0].nodeValue;
        if (vlhkostValTest.includes("N/A")) {
          this.vlhkostVal = "-";
        } else {
          this.vlhkostVal = vlhkostValTest;
        }
        this.tlakVal =
          xmlDOC.getElementsByTagName("pressure")[0].childNodes[0].nodeValue;
        this.srazkyVal =
          xmlDOC.getElementsByTagName("rain")[0].childNodes[0].nodeValue; //.slice(0, -2);
        this.vitrVal =
          xmlDOC.getElementsByTagName("wind")[0].childNodes[0].nodeValue;
        this.compasTurn = xmlDOC
          .getElementsByTagName("windFrom")[0]
          .childNodes[0].nodeValue.slice(0, -1);

        let sunRiseTime1 = xmlDOC
          .getElementsByTagName("sunrise")[0]
          .childNodes[0].nodeValue.substr(0, 5);
        let sunRiseTime2 = xmlDOC
          .getElementsByTagName("sunrise")[0]
          .childNodes[0].nodeValue.substr(8, 9);
        this.sunriseTime = sunRiseTime1 + sunRiseTime2;

        let sunsetTimeFull =
          xmlDOC.getElementsByTagName("sunset")[0].childNodes[0].nodeValue;
        let sunsetTime1 = sunsetTimeFull.substr(0, 5);
        let sunsetTime2 = sunsetTimeFull.substr(8, 9);
        this.sunsetTime = sunsetTime1 + sunsetTime2;

        var moonPhase =
          xmlDOC.getElementsByTagName("moonPhase")[0].childNodes[0].nodeValue;
        switch (moonPhase) {
          case "New Moon":
            this.moonPhase = "0";
            this.actualMoonPhase = 0;
            break;
          case "Waxing Crescent Moon":
            this.moonPhase = "60";
            this.actualMoonPhase = 1;
            break;
          case "Quarter Moon":
            this.moonPhase = "90";
            this.actualMoonPhase = 2;
            break;
          case "Waxing Gibbous Moon":
            this.moonPhase = "120";
            this.actualMoonPhase = 3;
            break;
          case "Full Moon":
            this.moonPhase = "180";
            this.actualMoonPhase = 4;
            break;
          case "Waning Gibbous Moon":
            this.moonPhase = "240";
            this.actualMoonPhase = 5;
            break;
          case "Last Quarter Moon":
            this.moonPhase = "270";
            this.actualMoonPhase = 6;
            break;
          case "Waning Crescent Moon":
            this.moonPhase = "300";
            this.actualMoonPhase = 7;
            break;
        }
        this.moonFullness =
          xmlDOC.getElementsByTagName(
            "moonFullness"
          )[0].childNodes[0].nodeValue;
      });
    },
  },
};
</script>

<style scoped>
.mapa_meteoBlok {
  display: flex;
  flex-direction: column;
}
.mapaBlok,
.meteoBlok {
  display: flex;
  flex-direction: column;
  height: 440px;
}
.hlavickaMapa,
.hlavickaMeteo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0154ce;
  color: white;
  margin-top: 1px;
  height: 40px;
}
.mapa {
  height: 400px;
}

.hlavickaMapa p,
.hlavickaMeteo p {
  text-align: center;
  top: 30%;
  font-weight: 700;
  margin-top: 8px;
}
.meteoBody {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: center;
  align-content: center;
  height: 399px;
  color: #0154ce;
  background-color: rgb(255, 241, 219);
  border: 2px solid white;
}
.meteoHodnoty {
  display: flex;
  flex-basis: 49%;
  height: 250px;
  justify-content: center;
  align-items: center;
}
.meteoHodnoty table {
  flex-basis: 100%;
}
td {
  padding-top: 0.3em;
  vertical-align: center;
  font-size: 0.9em;
}
td:nth-child(1) {
  text-align: right;
  padding-right: 5%;
  font-family: "gibsonlight";
  font-weight: 600;
  width: 50%;
}
td:nth-child(2) {
  text-align: left;
}
.meteoCompas {
  display: flex;
  flex-direction: column;
  flex-basis: 49%;
  align-items: center;
  justify-content: center;
  height: 250px;
  border-left: 2px solid white;
}
.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/Pics/compasRedSmall_Bckgrd.jpg");
  background-position: center;
  height: 150px;
  width: 150px;
  border: 1px solid white;
  border-radius: 50%;

  border: 1px solid rgb(173, 173, 173);
}
.compasImg {
  border-radius: 50%;
  height: 80px;
}
.meteoCompas table {
  width: 100%;
}
.meteoSunMoon {
  display: flex;
  flex-basis: 100%;
  justify-content: space-around;
  align-items: center;
  border-top: 2px solid white;
  height: 150px;
  color: #0154ce;
  font-size: 0.8em;
}
.meteoSunMoonBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 33%;
  margin: 2%;
}
.imgSun {
  height: 65px;
}
.imgMoon {
  width: 65px;
  border-radius: 50%;
}
a {
  text-decoration: none;
  color: rgb(173, 173, 173) !important;
}
</style>